<template>
  <div>
    <v-dialog
      v-model="dialogAdd"
      transition="scale-transition"
      persistent
      max-width="400px"
    >
      <v-card outlined>
        <v-card-title class="title">
          {{ $t("absent.added_manual") }}
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="modelStatus"
                  :items="statusItems"
                  :rules="[v => !!v || $t('app.required')]"
                  :loading="loadingStatusItems"
                  label="Status"
                  item-value="id"
                  item-text="label"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-textarea
                  v-model="infoAddAbsent"
                  :label="$t('journal.table.information')"
                  outlined
                  rows="3"
                  hide-details
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              outlined
              small
              dark
              class="mr-2 gradient-error"
              @click="cancelAbsent"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              :loading="loadingSave"
              outlined
              class="gradient-primary"
              dark
              small
              @click="saveAbsent"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { lessonAbsentStatus } from "@/api/admin/absent/absent";

export default {
  props: {
    dialogAdd: Boolean,
    loadingSave: Boolean
  },
  mounted() {
    this.getlessonAbsentStatus();
  },
  watch: {
    dialogAdd(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
    }
  },
  data() {
    return {
      loadingStatusItems: false,
      modelStatus: null,
      statusItems: [],
      infoAddAbsent: ""
    };
  },
  methods: {
    async getlessonAbsentStatus() {
      try {
        this.loadingStatusItems = true;
        const response = await lessonAbsentStatus();
        if (response.data.code) {
          this.statusItems = response.data.data.filter(item => item.id !== "0");
        }
      } catch (error) {
        console.error("getlessonAbsentStatus()\n", error);
      } finally {
        this.loadingStatusItems = false;
      }
    },
    cancelAbsent() {
      this.$emit("close");
    },
    saveAbsent() {
      if (this.$refs.form.validate()) {
        this.$emit("save", {
          status: this.modelStatus,
          note: this.infoAddAbsent
        });
      }
    }
  }
};
</script>
